import React from 'react'
import LogoFoundationGray from '../images/Logo-100.png'
import LogoFoundationBlack from '../images/Logo-100.png'
import FooterIconAddress from '../images/footer-icon-address.svg'
import FooterIconEmail from '../images/footer-icon-email.svg'
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter.svg'
import Instagram from '../images/instagram.svg'
import Visa from '../images/visa.svg'
import Mastercard from '../images/mastercard.svg'
import '../App.css'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
     <footer className='footer-section font-face-sh py-5 position-relative inner-footer bg-branding-green-footer'>
        <div className='containers px-5'>
         
          <div className='row py-4 border-bottom'>
            <div className='col-sm-12 col-md-12 col-lg-3 black-clr'>
              <div className='footer-logo'>
              <img className='' src={LogoFoundationGray} alt="logo-foundation-gray" style={{ width: 200 }} />
              </div>

              {/* <h4 className='mb-4 pb-2 pt-2 font-face-sh-bold'>Contact us</h4>
              <div className='footer-address d-flex align-items-start'>
                <img className='me-3' src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className='footer-email d-flex align-items-start'>
                <img className='me-3' src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Support: <a href="mailto:hello@playfundwin.com" className='text-decoration-none black-clr branding-1-hover'>hello@playfundwin.com</a></p>
              </div> */}
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4'>
              <div className='row'>
                {/* <div className='col-sm-12 col-md-12 col-lg-6 black-clr mb-3'>
                  <p className='mt-4 pt-2 font-face-sh-bold'>Follow us</p>
                  <div className='d-flex'>
                    <a className="black-clr text-decoration-none" href="https://facebook.com/PlayFundWin" target='_blank'><img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} /></a>
                    <a className='black-clr text-decoration-none' href="https://twitter.com/PlayFundWin" target='_blank'><img className='me-3' src={Twitter} alt="Twitter" style={{ width: 24 }} /></a>
                    <a className='black-clr text-decoration-none' href="https://www.instagram.com/playfundwin/" target='_blank'><img className='me-3' src={Instagram} alt="Instagram" style={{ width: 24 }} /></a>
                  </div>
                </div> */}
                {/* <p className='fs-4 mb-1 text-white'>2 Ebor St, London, E1 6AW</p> */}
                <p className='fs-4 mb-1 text-black'>For support with the website, please <a href="mailto:hello@playfundwin.com" className='branding-4'>email us</a> here</p>
                
              </div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-5 d-flex justify-content-end black-clr text-center'>
              {/* <p className='mt-4 pt-3' style={{ fontSize: 13 }}>The 100 Daily Draw Prize Draw is operated by <a className='text-decoration-none black-clr branding-1-hover' href="https://playfundwin.com/" target="_blank">Play Fund Win</a></p>
              <div className='d-flex' style={{ fontSize: 14 }}>
                <Link className='black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold' to={'/terms-conditions'}>Terms & Conditions</Link>
                <Link className='black-clr text-decoration-none branding-1-hover font-face-sh-bold' to={'/privacy-policy'}>Privacy Policy</Link>
              </div> */}
              <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='d-flex'>
                    <img className='me-3' src={Visa} alt="Visa" style={{ width: 64 }} />
                    <img className='me-3' src={Mastercard} alt="Mastercard" style={{ width: 64 }} /> <span className='fs-5' style={{color : '#000',marginTop : 7,marginRight : 7}}>mastercard</span>
                    <img className='me-3' src={Mastercard} alt="Mastercard" style={{ width: 64 }} /> <span className='fs-5' style={{color : '#000',marginTop : 7,marginRight : 7}}>maestro</span>
                  </div>
                </div>
            </div>
          </div>
          <div className='row py-4 copyright'>
            <div className='col-md-6'>
            <span className='fs-4 text-black' style={{marginRight : 10}}>Powered by</span> <a className='text-decoration-none black-clr branding-1-hover' href="https://playfundwin.com/" target="_blank"><img className='mx-auto' src={require('../images/pfw-logo-dark.png')} /></a>
            </div>
            <div className='col-md-6'>
            <div className='d-flex justify-content-end' style={{ fontSize: 14 }}>
                <Link className='text-black text-decoration-none pe-5 fs-5 branding-1-hover font-face-sh-bold' to={'/terms-conditions'}>Terms & Conditions</Link>
                <Link className='text-black text-decoration-none fs-5 branding-1-hover font-face-sh-bold' to={'/privacy-policy'}>Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
