import React from 'react'
import { useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Logo from '../images/Logo-100.png'
import secureLocalStorage from 'react-secure-storage'
import axios from "axios";
import apiClient from '../api.config'
import useDocumentTitle from '../components/useDocumentTitle'

export default function Login() {
  useDocumentTitle('Login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  async function validatUser() {
    if (email.trim().length === 0 && password.trim().length === 0) {
      toast.error(`Please Enter Your Email and Password`);
    } else if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      toast.error(`Invalid Email`);
    } else if (password.trim().length === 0) {
      toast.error(`Please Enter Your Password`);
    } else {
      try {

        const response = await toast.promise(apiClient.post(
          `${process.env.REACT_APP_API_URL}/auth/local`,
            {
              userEmail: email,
              userPassword: password
            },
            {
              withCredentials: true,
              credentials: "same-origin"
            }
          ),
          {
            pending: 'Please wait...',
          }
        );
        console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast("Successfully Authenticated", { type: "success" });
          secureLocalStorage.setItem("LogObject",{userID:response.data.content.userID,email:email,loggedin:true})
          navigate('/account');
          const userID = response.data.content.userID;

          // For sign-in
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'user_signed_in',
            'user_id': userID,
            'user_email': email
          });
        } else {
          toast("Something went wrong While authenticating", { type: "error" });
        }
      } catch (error) {
        console.log(error);
        if(error.response.status === 403){
          toast.error(`${error.response.data.content.message}`);
        }else{
          toast.error(`${error.message}`);
        }
      }

    }
  }
  return (
    <>
      <ToastContainer />
      <section className='login-section font-face-sh'>
        <div className='container'>
          <div className="login-box mx-auto mt-4" style={{ maxWidth: 360 }}>
            <div className="login-header text-center pt-1">
              <Link to={'/'}>
              <img className="logo mb-3" src={Logo} style={{ maxHeight: 70 }} />
              </Link>
              <div id="myTreesPromo" className="promo-area" style={{ display: 'none' }}>
                Save 1 tree by simply signing up
              </div>
              <h3 className="title text-center fs-22 mb-3 pb-1" id="title" style={{ display: 'block' }}>Log in to 100 Daily Draw</h3>
              <h5 className="subtitle text-start mb-4" id="toggleTypeContainer" style={{ display: 'block', fontSize: 15 }}>
                <span id="hint" style={{ display: 'inline', color: '#43465E' }}>Don't have an account?</span>
                <span className="subtitle-link" id="login-instead"><Link className='text-decoration-none branding-1' to={"/signup"}> Join for free</Link></span>
              </h5>
            </div>
            <div id="emailPassword" style={{ display: 'block' }}>
              <input type="email" id="email" placeholder="Email address" onChange={(event) => setEmail(event.target.value)} className="input" style={{ display: 'block' }} />
              <input type="password" onChange={(event) => setPassword(event.target.value)} id="password" placeholder="Password" className="input" style={{ display: 'block' }} />

              <button type="submit" onClick={() => validatUser()} id="btn-signup" className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-pill py-3 mt-3 mb-2 lh-1 desktop-btn text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', display: 'block' }}>Continue with email</button>
              <div id="forgot" className="forgot"><Link className='text-decoration-none' to={"/reset-password"} style={{ color: 'rgb(198, 29, 35)' }}>I forgot my password</Link></div>

            </div>
            <div id="error-message" className="error" style={{ display: 'none' }}>error</div>
            <p id="socialOrDivider" className="line-subtitle orDivider" style={{ display: 'none' }}><span>Or</span></p>
            <div id="socialApple" style={{ display: 'none' }}>
              <button type="button" id="btn-apple" className="social-button social-button-apple">Continue with Apple</button>
            </div>
            <div id="socialGoogle" style={{ display: 'none' }}>
              <button type="button" id="btn-google" className="social-button social-button-google">Continue with Google</button>
            </div>
            <p className="terms" id="terms" style={{ display: 'none' }}>By signing in to <span id="product-name">100 Daily Draw</span>, you agree to our <Link className="terms-link" id="terms-link" to="/terms-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</Link> and <Link className="terms-link" id="privacy-policy-link" to="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></p>
          </div>

        </div>
      </section>
    </>
  )
}