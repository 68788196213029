import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function PrizeSlider() {

  // const siteId = process.env.REACT_APP_SITE_ID;
  // const APIURL = process.env.REACT_APP_API_URL;

  // const [scroll, setScroll] = useState(false);
  // const [DrawData, setDrawData] = useState([]);
  // const [PrizeData, setPrizeData] = useState([]);
  // const parser = new DOMParser();
  // const [totalQuantities, setTotalQuantities] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div className="prize-block-inner px-0 py-5 pt-0">
      <Slider {...settings}>

      {/* {PrizeData?.map(item => {
          return (
          <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={item.prizeImageURL} className="img-fluid" alt="Signed" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-1" dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.prizeName}`, 'text/html').body.textContent }} />
              </div>
           </div> 
          </div>
            )
        })} */}

      <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={require('../images/prizeslide1.png')} className="img-fluid" alt="Signed" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
           </div> 
          </div>


          <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={require('../images/prizeslide2.png')} className="img-fluid" alt="Training" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              {/* <div className="prize-block-testimonial bg-white text-center py-3 col-sm-12 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 12 ,borderBottomRightRadius : 12 }}>
                  <h4 className="branding-2">Treatments and wellness sessions</h4>
              </div> */}
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={require('../images/prizeslide3.png')} className="img-fluid" alt="Choir" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
             
           </div> 
          </div>

          <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={require('../images/prizeslide4.png')} className="img-fluid" alt="Frozen" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              
           </div> 
          </div>


          <div className="prize-block">
            <div className="rounded-3 m-2">
              <div className="prize-photo col-sm-12 col-md-12 col-lg-12 text-center p-3 position-relative" style={{ borderRadius : 12}}>
                  <img src={require('../images/prizeslide5.png')} className="img-fluid" alt="Frozen" style={{height : '100%', width : '100%', objectFit : 'cover', borderRadius : 12}}/>
              </div>
              
           </div> 
          </div>


          

      </Slider>
    </div>
  );
}
