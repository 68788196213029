import React, { useState } from 'react'
import { Link, useParams } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/footer';
import { useEffect } from 'react';
import axios from 'axios';
import LogoWhite from '../images/Logo-100.png'
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter.svg'
import Instagram from '../images/instagram.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import secureLocalStorage from 'react-secure-storage';
import DashboardHeader from '../components/Header'
import useDocumentTitle from '../components/useDocumentTitle';

export default function ThankYou(props) {
  useDocumentTitle('Payment Success');

  const params = useParams();
  const [scroll, setScroll] = React.useState(false);
  const [Sidebar, setSidebar] = React.useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setisValid] = useState(false);
  const [transactionContent, setTransactionContent] = useState({});
  const [entriesContent, setEntriesContent] = useState([]);
  const [entryContent, setEntryContent] = useState([]);
  const [entryData, setEntryData] = useState([]);
  const [tickets, setTickets] = useState('');
  const [login, setLogin] = useState(secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').loggedin : false);
  const TransactionID = params.transactionID;
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;
  const [entryDrawData, setEntryDrawData] = useState([]);
  const [isoneoff, setIsoneoff] = useState(false);
  const userData = secureLocalStorage.getItem('LogObject');
  
  function logout() {
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setLogin(false);
  }
  const WaitForProcess = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/transactions/paymentSuccess/${TransactionID}`, {
      withCredentials: true,
      credentials: "same-origin"
    }).then(res => {
      if (res.data.content.transaction && res.data.content.entries[0]) {
        setisValid(true);
        console.log("API Response", res.data.content);
        setTransactionContent(res.data.content.transaction);
        setEntriesContent(res.data.content.entries);
        setEntryData(res.data.content.entries[0]);
        setEntryDrawData(res.data.content?.entries[0]?.draw);
        setEntryContent(res.data.content?.entries[0]?.draw?.content?.contentBody ? JSON.parse(res.data.content.entries[0].draw.content.contentBody):[]);
        let t = '';
        {
          res.data.content.entries[0].tickets?.map((e, index) => {
            t += `${e.ticketNumber}${res.data.content.entries[0]?.tickets?.length == index + 1 ? "" : ", "}`;
          })
        }
        setTickets(t);
      } else {
        setisValid(false);
        console.log("Invalid URL");
      }
      setIsLoaded(true);
    })
  }, [])

  // Data Layer Push for Purchase event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'purchase',
    'ecommerce': {
      'purchase': {
        'actionField': {
          'transaction_id': TransactionID,
          'affiliation': 'Online',
          'value': entryData.drawEntryTotal,
          'item_name': entryDrawData.drawName,
          'item_id': entryDrawData.drawID,
          'user_id': userData?.userID ? userData?.userID : 'Guest User',
          'tax': '0',
          'shipping': '0',
          'currency': 'GBP',
          'coupon': ''
        },
        'items': [
          {
            'item_name': entryDrawData.drawName,
            'item_id': entryDrawData.drawID,
            'price': entryData.drawEntryTotal,
            'item_brand': 'Sport',
            'item_category': entryDrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : entryDrawData.drawCategoryID === 2 ? 'Split Cash Prize' : entryDrawData.drawCategoryID === 3 ? 'Experiential' : entryDrawData.drawCategoryID === 4 ? 'Hybrid' : '',
            'item_variant': entryData.drawEntryType != 1 ? 'Subscription' : 'One-off',
            'quantity': entryData.drawEntries
          }
        ]
      },
      'user_id': userData?.userID ? userData?.userID : 'Guest User',
      'user_email': userData?.email ? userData?.email : 'Guest User'
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    })
  }, [])


  return (
    <>
    
<DashboardHeader />

      {!isLoaded ? <></> : 
      <div>
        <div className="container-fluid home-left-side mb-md-0 position-relative thankyou-header" style={{ background: `linear-gradient(to right,  rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),  url(${require(`../images/100-daily-banner.png`)})`,  paddingTop: 90, paddingBottom: 90, backgroundRepeat: 'no-repeat', backgroundPosition : 'top',  zIndex: 9, backgroundSize : 'cover'}}>
          <div className='container'>
            <div className="row align-items-center mx-auto" style={{ width: '100%' }}>
              <div className="col-md-8 align-middle pb-md-0 pb-4 font-face-gb thank-you-title">
                <h1>
                  <strong className='font-face-gb-semibold display-1 text-white font-face-sh-bold'>Thank You!</strong></h1>
                <h3 className="branding2 fw-normal text-white lh-base">For entering the 100 Daily Draw Prize Draw</h3>
              </div>
              <div className="col-md-4 align-middle pb-md-0 pb-4 font-face-gb logo-thank-you position-relative" style={{ marginBottom: -200 }}>
                <div className='share-icons w-50' style={{ margin: '0 auto', paddingBottom: 30, marginTop: -40 }}>
                  <img src={require('../images/sharethanks.png')} width={'100%'} style={{ paddingBottom: 20 }}></img>
                  <div className="d-flex">
                    <a className="mx-1" href={`http://www.facebook.com/sharer.php?u=${window.location.href}&quote=${entryContent.facebookShareText ? entryContent.facebookShareText : `I have entered the ${entryContent.name ? entryContent.name:entryData.draw.drawName} to raise essential funds for ${entryContent.name ? entryContent.name:entryData.draw.drawName}. Why not join me by buying a ticket here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`}`} target="_blank">
                      <img src={require('../images/facebook-thank.png')} width={'100%'}></img>
                    </a>
                    <a className="mx-1" href={`https://twitter.com/share?url=${window.location.href}.&text=${entryContent.twitterShareText ? entryContent.twitterShareText : `I just helped ${entryContent.name ? entryContent.name:entryData.draw.drawName} to raise essential funds by entering the ${entryContent.name ? entryContent.name:entryData.draw.drawName} via @playfundwin Why not join me by entering the draw here: ${window.location.href}`}`} target="_blank">
                      <img src={require('../images/twitter-thank.png')} width={'100%'}></img>
                    </a>
                    <a className="mx-1" href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${entryContent.name ? entryContent.name:entryData.draw.drawName}&summary ${entryContent.linkedInShareText ? entryContent.linkedInShareText : `I’m helping ${entryContent.name ? entryContent.name:entryData.draw.drawName} to raise essential funds for ${entryContent.name ? entryContent.name:entryData.draw.drawName}. All net proceeds will go to supporting to ${entryContent.name ? entryContent.name:entryData.draw.drawName}. You can join me in supporting this brilliant cause by buying a ticket here: ${window.location.href}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`}`} target="_blank">
                      <img src={require('../images/linkedin-thank.png')} width={'100%'}></img>
                    </a>
                    <a className="mx-1" href={`https://wa.me/?text=${entryContent.whatsappShareText ? entryContent.whatsappShareText : `Hi! I just entered the ${entryContent.name ? entryContent.name:entryData.draw.drawName} to raise essential funds for ${entryContent.name ? entryContent.name:entryData.draw.drawName}. Your support could make a huge difference, enter here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause!`}`} target="_blank">
                      <img src={require('../images/whatsapp-thank.png')} width={'100%'}></img>
                    </a>
                  </div>
                </div>
                <div className='box-logo rounded-5 py-5 px-3 text-center shadow-sm position-relative' style={{ backgroundColor: '#F2F2F2' }}>
                  <img src={LogoWhite} className="px-2" />
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className="container-fluid thank-home home-left-side mb-md-0" style={{ paddingTop: 400, paddingBottom: 90 }}>
          <div className='container'>
            <div className='ticket-data col-md-9' style={{ marginTop: -350 }}>
              <h2 className='branding-1 display-3 py-2 font-face-sh-bold'><strong>You're in the draw!</strong></h2>
              <h3 className="display-6 py-2" style={{ opacity: 0.5 }}>Your ticket numbers are:</h3>
              <h3 className="display-6 text-dark py-2" style={{ fontWeight: 500, wordWrap: "break-word" }}>{tickets}</h3>
              <div className='border-bottom py-2 border-dark'></div>
              <h3 className="branding-1 display-6 py-4" style={{ color: '#8f9193' }}>Amount : <span className='position-relative thankyou-amount branding-1 display-5 font-face-sh-bold' style={{ opacity: 1 }} ><strong>£{entryData.drawEntryTotal}</strong></span></h3>
              <p className="pb-2 fs-4" style={{ color: '#8f9193' }}>You're in the draw, Good Luck, Fingers crossed you are the lucky winners!</p>
              <div className='border-bottom pb-3 border-dark'></div>
              <p className="py-4 fs-4" style={{ color: '#8f9193' }}>Keep an eye on your emails to find out if you have won!</p>
              <Link to={'/account/subscription'} className="btn thankyou-page-button text-uppercase bg-branding-1 border-0 rounded-pill py-3 px-5 lh-1 text-light " style={{ lineHeight: 2 }}>View yours tickets</Link>
            </div>
          </div>
        </div>

      </div>

      }
  <Footer/>
    </>
  )
}
