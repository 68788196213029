import React, { useEffect, useState } from 'react'
import DashboardHeader from '../components/dashboard-header'
import Footer from '../components/footer'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import apiClient from '../api.config'
import useDocumentTitle from '../components/useDocumentTitle'
import { toast, ToastContainer } from 'react-toastify'

export default function Details() {
  useDocumentTitle('Edit Profile');
  const navigate = useNavigate();
  const [isLoaded, setLoadComplete] = useState(false);
  const [PurchaseData, setPurchaseData] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setFirstName] = useState('');
  const [userLastName, setLastName] = useState('');
  const [userAddreess1, setAddreess1] = useState('');
  const [userAddreess2, setAddreess2] = useState('');
  const [userPostcode, setUserPostcode] = useState('');
  const [userFetch, setUserFetch] = useState();
  const userData = secureLocalStorage.getItem('LogObject');
  
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    if (!userData) {
      navigate('/login');
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/users/${userData.userID}/data`, {
        withCredentials: true,
        credentials: "same-origin"
      }
      ).then(res => {
        setUserFetch(res.data.content)
        setUserEmail(res.data.content.userEmail);
        setFirstName(res.data.content.userFirstName);
        setLastName(res.data.content.userSurname);
        setAddreess1(res.data.content.userAddressLine1);
        setAddreess2(res.data.content.userAddressLine2);
        setUserPostcode(res.data.content.userAddressPostcode);
        setLoadComplete(true);
      }
      );
    }
  }, [])

  const handleSubmit = () => async () => {
    if (!userFirstName) {
      toast.error("First Name is important field");
    } else if (!userEmail) {
      toast.error("Email is important field");
    } else if (!userEmail) {
      toast.error("Email is important field");
    } else if (!userAddreess1) {
      toast.error("Address is important field");
    } else if (!userPostcode) {
      toast.error("Address Postcode is important field");
    } else {
      try {

        await toast.promise(apiClient.put(`${process.env.REACT_APP_API_URL}/users`,
          {
            "userID": userFetch.userID,
            "userFirstName": userFirstName,
            "userSurname": userLastName,
            "userEmail": userEmail,
            "userAddressLine1": userAddreess1,
            "userAddressLine2": userAddreess2,
            "userAddressPostcode": userPostcode,
            "userMeta": userFetch.userMeta,
          },
          {
            withCredentials: true,
            credentials: "same-origin"
          }), {
          pending: 'Please wait...',
          success: 'Update Successfully',
          error: 'something went wrong'
        })
      } catch (error) {
        console.log(error);
      }

    }

  }


  return (
    <>
      <DashboardHeader active='details' />
      {isLoaded ? <>
        <div className='user-subscription-section font-face-sh pb-5'>
          <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 500, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
            <h3 className='text-start fs-14 pb-2 m-0' style={{ color: '#302d51' }}><strong>Edit profile</strong></h3>
            <div>
              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>first Name</strong></p>
              <input type="text" id="fname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="First Name" className="input" style={{ display: 'block' }} />

              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>Last Name</strong></p>
              <input type="text" id="fname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Last Name" className="input" style={{ display: 'block' }} />

              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>Email</strong></p>
              <input type="text" id="fname" value={userEmail} onChange={(event) => setUserEmail(event.target.value)} placeholder="Email address" className="input" style={{ display: 'block' }} />

              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>Address line 1</strong></p>
              <input type="text" id="fname" value={userAddreess1} onChange={(event) => setAddreess1(event.target.value)} placeholder="Address line 1" className="input" style={{ display: 'block' }} />

              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>Address line 2</strong></p>
              <input type="text" id="fname" value={userAddreess2} onChange={(event) => setAddreess2(event.target.value)} placeholder="Address line 2" className="input" style={{ display: 'block' }} />

              <p className='text-start fs-14 my-2 m-0' style={{ color: '#302d51' }}><strong>Address Postcode</strong></p>
              <input type="text" id="fname" value={userPostcode} onChange={(event) => setUserPostcode(event.target.value)} placeholder="Address Postcode" className="input" style={{ display: 'block' }} />


            </div>
          </div>
        </div>
        <div className='mx-auto text-center pb-5' style={{ maxWidth: 327 }}>
          <button onClick={handleSubmit()} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh'>Submit</button>
        </div>
      </> : <div className='user-subscription-section font-face-sh pb-5'>
        <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
          <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
        </div>
      </div>}
      <ToastContainer />
      <Footer />
    </>
  )
}