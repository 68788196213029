import { React, useState , useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import secureLocalStorage from 'react-secure-storage'
import LogoWhite from '../images/Logo-100.png'
import axios from 'axios'
import Moment from 'moment';
import Header from '../components/Header';
import Facebook from '../images/facebook.svg'
import Twitter from '../images/twitter.svg'
import Instagram from '../images/instagram.svg'
import Visa from '../images/visa.svg'
import Mastercard from '../images/mastercard.svg'
import LogoFoundationWhite from '../images/logo-foundation-white.svg'
import Cup from '../images/cup.svg'
import  '../images/HeroBg.png'
import '../App.css'
import LogoFoundationGray from '../images/Logo-100.png'
import CurevedDot from '../images/cureved-dot.svg'
import FooterIconAddress from '../images/footer-icon-address.svg'
import FooterIconEmail from '../images/footer-icon-email.svg'
import DashboardHeader from '../components/Header'
import Footer from '../components/footer'
import useDocumentTitle from '../components/useDocumentTitle';


export default function PageNotFound() {
  useDocumentTitle('Page Not Found');
  const [scroll, setScroll] = useState(false);
  const [Sidebar,setSidebar] = useState(false);
  const [login ,setLogin] = useState(secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').loggedin : false);
  const [DrawData,setDrawData] = useState([]);
  
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  function logout(){
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setLogin(false);
  }

  return (
    
    <>
   
    <DashboardHeader />
    <section className="w-100 bg-f2  p-5 position-relative notfound-page">
        <div className="container h-100 p-5 notfound-page">
          <div className="d-flex align-items-center justify-content-center h-100 ">
            <div className="d-flex flex-column w-75 row notfound">
              <div className='align-items-center justify-content-center text-center'>
                <img src={require('../images/404pfw.png')} style={{width : "100%"}}/>
                <h1 className='display-4 mb-5 font-face-sh-bold'>Ohh... Shoot, this isn't the right place</h1>
                <h5 className='color-grey'>This page you have navigated doesn't exist or has been moved. Our apologies</h5>
                <div className='pt-0'>
                  <Link to={'/'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4 w-25 m-3 rounded-pill py-3 mt-5 lh-1">Home</Link>
                  <Link to={'/login'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4  w-25 m-3 rounded-pill py-3 mt-5 lh-1">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      </>
  )
}